// Css namespace - based on BEMIT
$css-prefix:                  ev !default;

$lock-slider-width:           15em;
$lock-slider-padding:         1em;

.#{$css-prefix}-credit-lock {
  text-align: center;
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
}

.#{$css-prefix}-credit-lock--unlocked {
  background: $color-bg-main;
}

.#{$css-prefix}-credit-lock--locked {
  background: $color-bg-locked;
}

.#{$css-prefix}-credit-lock--frozen {
  background: $color-bg-frozen;
}

.#{$css-prefix}-credit-lock__container {
  max-width: 1200px;
  margin: 0 auto;
}

.#{$css-prefix}-credit-lock__lock {
  max-width: 22rem;
  display: inline-block;
  padding-top: 2em;
}

.#{$css-prefix}-credit-lock--locked  .#{$css-prefix}-unlocked--active,
.#{$css-prefix}-credit-lock--unlocked  .#{$css-prefix}-locked--active {
  display: none;
}
.#{$css-prefix}-credit-lock__header--frozen {
  font-size: 1rem;
}

.#{$css-prefix}-credit-lock__preamble {
  margin: 0;
  font-size: 0.875rem;
  font-weight: 400;
}

.#{$css-prefix}-credit-lock__preamble--frozen {
  color: $color-text-alt;
  font-weight: 400;
}

.#{$css-prefix}-credit-lock__status {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 2.5rem;
  @media only screen and (min-width: 768px) {
    font-size: 4rem;
    line-height: 4rem;
  }
}

.#{$css-prefix}-credit-lock__status--frozen {
  color: $color-text-alt;
}

.#{$css-prefix}-credit-lock__instructions {
  font-size: 0.75rem;
}

.#{$css-prefix}-credit-lock__instructions--frozen {
  color: $color-text-alt;
  font-size: 0.875rem;
}

.#{$css-prefix}-credit-lock__lock-slider {
  display: block;
  width: ($lock-slider-width + (2*$lock-slider-padding));
  margin: 2.5em auto 2em auto;
  padding: $lock-slider-padding;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 5em;
}

.#{$css-prefix}-credit-lock__lock-input {
  display: none;
}

.#{$css-prefix}-credit-lock__lock-button {
  position: relative;
  display: block;
  width: $lock-slider-width;
  height: 7.5em;
  padding: 2px;
  background: $color-bg-accent;
  border: 12px solid $color-border-accent;
  border-radius: 4em;
  outline: 0;
  cursor: pointer;
  transition: all .4s ease;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:before {
    position: absolute;
    top: 20%;
    left: 70%;
    display: inline-block;
    width: 44px;
    height: 40px;
    margin: 0;
    background-image: url("/images/efxdatabreach/icon_small_unlocked.png");
    background-size: 44px 40px;
    content: '';
    @media only screen and (min-width: 768px) {
      width: 55px;
      height: 50px;
      background-size: 55px 50px;
    }
  }
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 28%;
    height: 62%;
    margin: 0;
    background: transparent;
    border: 16px solid $color-border-tertiary;
    border-radius: 3em;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.1), 0 4px 0 rgba(0,0,0,0.08);
    transition: left 0.4s cubic-bezier(0.575, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
    -webkit-transform: initial;
    -ms-transform: initial;
    transform: initial;
    content: '';
    @media only screen and (min-width: 768px) {
      border: 18px solid $color-border-tertiary;
    }
  }
}

.#{$css-prefix}-credit-lock--locked #credit-lock-toggle:checked ~ .#{$css-prefix}-credit-lock__lock-button:after {
  border-color: $color-border-tertiary;
}


.#{$css-prefix}-credit-lock--locked #credit-lock-toggle:not(:checked) ~ .#{$css-prefix}-credit-lock__lock-button,
#credit-lock-toggle:checked ~ .#{$css-prefix}-credit-lock__lock-button {
  background: $color-bg-alt;
  border: 12px solid $color-border-main;
}

#credit-lock-toggle:checked ~ .#{$css-prefix}-credit-lock__lock-button:after {
  left: 55%;
  border-color: transparent;
}
#credit-lock-toggle:checked ~ .#{$css-prefix}-credit-lock__lock-button:before {
  position: absolute;
  top: 20%;
  left: 10%;
  display: inline-block;
  width: 32px;
  height: 40px;
  margin: 0;
  background-image: url("/images/efxdatabreach/icon_small_locked.png");
  background-size: 32px 40px;
  content: '';
  @media only screen and (min-width: 768px) {
    width: 41px;
    height: 50px;
    background-size: 41px 50px;
  }
}

.#{$css-prefix}-credit-lock__frozen {
  display: inline-block;
  margin: 1em 0;
}

.#{$css-prefix}-credit-lock__frozen-icon {
  display: inline-block;
  width: 84px;
  height: 92px;
  background-image: url("/images/efxdatabreach/icon_dashboard-frozen.png");
  background-size: 84px 92px;
  @media only screen and (min-width: 768px) {
    width: 140px;
    height: 154px;
    background-size: 140px 154px;
  }
}

.#{$css-prefix}-credit-lock__lock-spinner {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  z-index: 2;
  width: 83px; //matches width of slider circle
  height: 83px; //matches width of slider circle
  border: 16px solid $color-border-alt-accent; //solid portion of spinner
  border-bottom: 16px solid rgba(255,255,255,0.1); //empty portion of spinner
  border-radius: 100%;
  opacity: 0;
  animation: spin 1600ms linear infinite;
  transition: left 0.4s cubic-bezier(0.575, 0.885, 0.32, 1.275), opacity 0.2s ease;
  overflow: visible;
  @media only screen and (min-width: 768px) {
    width: 96px; //matches width of slider circle
    height: 96px; //matches width of slider circle
    border: 18px solid $color-border-alt-accent;
    border-bottom: 18px solid rgba(255,255,255,0.1);
  }
  &:before{
    position: absolute;
    top: 42px;
    left: -6px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $color-border-alt-accent;
    content: '';
    @media only screen and (min-width: 768px) {
      top: 50px;
      left: -5px;
      width: 18px;
      height: 18px;
    }
  }
  &:after{
    position: absolute;
    top: 42px;
    right: -6px;
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $color-border-alt-accent;
    content: '';
    @media only screen and (min-width: 768px) {
      top: 50px;
      right: -5px;
      width: 18px;
      height: 18px;
    }
  }
}

#credit-lock-toggle:checked ~ .#{$css-prefix}-credit-lock__lock-button .#{$css-prefix}-credit-lock__lock-spinner {
  left: 55%;
  opacity: 1;
}

.#{$css-prefix}-credit-lock--unlocked #credit-lock-toggle:checked ~ .#{$css-prefix}-credit-lock__lock-button:before {
  display: none;
}

.#{$css-prefix}-credit-lock--locked #credit-lock-toggle:checked ~ .#{$css-prefix}-credit-lock__lock-button .#{$css-prefix}-credit-lock__lock-spinner {
  left: 55%;
  opacity: 0;
}

.#{$css-prefix}-credit-lock--locked #credit-lock-toggle:not(:checked) ~ .#{$css-prefix}-credit-lock__lock-button .#{$css-prefix}-credit-lock__lock-spinner {
  opacity: 1;
}

.#{$css-prefix}-credit-lock--locked #credit-lock-toggle:not(:checked) ~ .#{$css-prefix}-credit-lock__lock-button:before {
  display: none;
}

.#{$css-prefix}-credit-lock--locked #credit-lock-toggle:not(:checked) ~ .#{$css-prefix}-credit-lock__lock-button:after {
  display: none;
}

// Keyframes for the spinner animation
@-moz-keyframes spin {
    from { -moz-transform: rotateZ(0deg); }
    to { -moz-transform: rotateZ(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotateZ(0deg); }
    to { -webkit-transform: rotateZ(360deg); }
}
@keyframes spin {
    from {transform:rotateZ(0deg);}
    to {transform:rotateZ(360deg);}
}

.#{$css-prefix}-credit-lock__freeze-button {
  text-decoration: none;
}

.#{$css-prefix}-credit-lock__info {
  position: relative;
  display: inline-block;
  width: 100%;
  margin-top: 2em;
  padding: 2.5em;
  background-color: $color-bg-tertiary;
  text-align: center;
  @media only screen and (min-width: 1024px) {
    float: right;
    width: 30%;
    max-width: 21rem;
    min-height: 35rem;
    margin: 0.25em 0.25em 0.25em 0;
    padding: 2.5em;
    text-align: left;
  }
  &:after {
    display: none;
    @media only screen and (min-width: 1024px) {
      position: absolute;
      top: 50%;
      left: -10px;
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid $color-bg-tertiary;
      border-bottom: 10px solid transparent;
      content: '';
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

.#{$css-prefix}-credit-lock__info--alt {
  background-color: $color-bg-tertiary;
  @media only screen and (min-width: 1024px) {
    background-color: $color-bg-main;
  }
  &:after {
    border-right: 10px solid $color-bg-tertiary;
    @media only screen and (min-width: 1024px) {
      border-right: 10px solid $color-bg-main;
    }
  }
}

.#{$css-prefix}-credit-lock__info-input {
  display: none;
}

.#{$css-prefix}-credit-lock__info-button {
  display: none;
  @media only screen and (min-width: 1024px) {
    display: inline-block;
    float: left;
    margin-top: 1em;
    padding-bottom: 0.5em;
    color: $color-link-main;
    border-bottom: 5px solid transparent;
    cursor: pointer;
  }
}

.#{$css-prefix}-credit-lock__info-button--right {
  float: right;
  text-align: right;
}

.#{$css-prefix}-credit-lock__info-input:checked + .#{$css-prefix}-credit-lock__info-button {
  color: $color-text-main;
  border-bottom: 5px solid $color-border-main;
  cursor: auto;
}

.#{$css-prefix}-credit-lock__info-description {
  display: none;
  margin: 0;
  font-size: 0.875rem;
  @media only screen and (min-width: 1024px) {
    padding-top: 1em;
    border-top: 1px solid $color-border-section-separator;
  }
}

.#{$css-prefix}-credit-lock__info-description--frozen {
  display: inline-block;
  padding-top: 0.75em;
  border-top: none;
}

#unlock-info-tab:checked ~ #unlock-info-description {
  display: inline-block;
}

#lock-info-tab:checked ~ #lock-info-description {
  display: inline-block;
}

.#{$css-prefix}-credit-lock-faqs {
  position: relative;
  padding: 0 2em 2em 2em;
  background-color: $color-bg-tertiary;
  @media only screen and (min-width: 1024px) {
    max-width: 60rem;
    margin: 0 auto;
    padding: 2em;
    background-color: $color-bg-main;
  }
}

.#{$css-prefix}-credit-lock-faqs__container {
  max-width: 60rem;
  margin: 0 auto 2em auto;
  padding: 2.5em 1.5em 1.5em 1.5em;
  background-color: $color-bg-main;
  border-radius: 6px;
  box-shadow: 0 1px 4px 0 rgba(51, 62, 72, 0.13);
  @media only screen and (min-width: 1024px) {
    margin: 0;
    padding: 2em;
    background-color: transparent;
    border-radius: 0;
    box-shadow: none;
  }
}

.#{$css-prefix}-credit-lock-faqs__indicator {
  display: none;
  @media only screen and (min-width: 1024px) {
    position: absolute;
    top: -10px;
    left: 0;
    right: 0;
    display: inline-block;
    width: 2em;
    height: 2em;
    margin: auto;
    background-color: $color-bg-main;
    border: 2px solid $color-border-main;
    border-radius: 50%;
    text-align: center;
  }
  &:after {
    display: none;
    @media only screen and (min-width: 1024px) {
      content: '';
      display: inline-block;
      width: 0.55em;
      height: 0.55em;
      margin-top: 0.45em;
      border-top: 3px solid $color-border-main;
      border-right: 3px solid $color-border-main;
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
}

.#{$css-prefix}-credit-lock-faqs__header {
  max-width: 10rem;
  margin: 0 auto 1.75em;
  text-align: center;
  @media only screen and (min-width: 768px) {
    max-width: 100%;
  }
}

.#{$css-prefix}-credit-lock-faqs__faq {
  display: none;
  @media only screen and (min-width: 1024px) {
    display: inline-block;
    border-top: 1px solid $color-border-section-separator;
    &:not(:first-of-type) {
      margin-top: 1em;
    }
  }
}

.#{$css-prefix}-credit-lock-faqs__faq--last {
  @media only screen and (min-width: 1024px) {
    margin-bottom: 2em;
    padding-bottom: 1em;
    border-bottom: 1px solid $color-border-section-separator;
  }
}

.#{$css-prefix}-credit-lock-faqs__faq--hidden {
  @media only screen and (min-width: 1024px) {
    display: none;
  }
}

.#{$css-prefix}-credit-lock-faqs__accordion-button {
  cursor: pointer;
  width: 100%;
  padding: 2em 1em 1em 0;
  border: none; //button override
  font-family: $font-family-base;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.25rem;
  text-align: left;
  background-color: transparent; //button override
  &:before {
    content: '+';
    float: right;
    color: $color-link-main;
    font-size: 1.5rem;
    font-weight: 700;
  }
}

.#{$css-prefix}-credit-lock-faqs__accordion-button--open {
  padding-bottom: 1em;
  &:before {
    content: '−';
  }
}

.#{$css-prefix}-credit-lock-faqs__accordion-button--question {
  color: $color-text-accent;
}

.#{$css-prefix}-credit-lock-faqs__accordion-panel {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.#{$css-prefix}-credit-lock-faqs__link {
  color: $color-text-main;
  &:hover {
    color: $color-text-main;
  }
}

.#{$css-prefix}-credit-lock-faqs__more-info {
  text-align: center;
  @media only screen and (min-width: 1024px) {
    margin-top: 0;
  }
}

.#{$css-prefix}-credit-lock-faqs__more-info span {
  display: none;
  @media only screen and (min-width: 1024px) {
    display: inline;
  }
}

.#{$css-prefix}-credit-lock-faqs__more-info__link {
  display: inline-block;
  margin-bottom: 1.5em;
  padding: 0.5em 1.75em;
  border: 1px solid $color-text-accent;
  border-radius: 25px;
  font-size: 0.875rem;
  text-align: center;
  text-decoration: none;
  &:hover {
    text-decoration: none;
    @media only screen and (min-width: 1024px) {
      text-decoration: underline;
    }
  }
  @media only screen and (min-width: 1024px) {
    margin: 0;
    padding: 0;
    border: none;
    font-size: 1rem;
    text-decoration: underline;
  }
}

.#{$css-prefix}-credit-lock__footnote {
  padding-top: 2em;
  border-top: 1px solid $color-border-section-separator;
  font-size: 0.7rem;
  @media only screen and (min-width: 1024px) {
    padding-top: 0;
    border-top: none;
  }
}

.#{$css-prefix}-credit-lock__info-description__tooltip {
  display: none;
  z-index: 3;
  @media only screen and (max-width: 1024px) {
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    padding: 2em 1em;
    background-color: rgba(51,62,72,0.7);
    overflow: auto;
  }
}

.#{$css-prefix}-credit-lock__info-description__tooltip__content {
  position: relative;
  left: 5%;
  width: 90%;
  margin-top: 1em;
  padding: 3em;
  font-size: 0.8rem;
  text-align: left;
  border-radius: 5px;
  background-color: $color-bg-main;
  z-index: 3;
  @media only screen and (min-width: 1024px) {
    top: initial;
    position: absolute;
    width: 88%;
    padding: 3.5em 2em;
    font-size: 0.85rem;
    background-color: $color-alert-banner;
  }
  &:after {
    display: none;
    @media only screen and (min-width: 1024px) {
      position: absolute;
      top: -10px;
      left: 50%;
      display: inline-block;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-bottom: 10px solid $color-alert-banner;
      border-right: 10px solid transparent;
      content: '';
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }
}

.#{$css-prefix}-credit-lock__info-description__tooltip__header {
  font-size: 1.75rem;
  @media only screen and (min-width: 1024px) {
    font-size: 1.17rem;
  }
}

.#{$css-prefix}-credit-lock__info-description__tooltip__close {
  position: absolute;
  top: 0.5em;
  right: 0;
  padding: 0;
  font-size: 2rem;
  font-weight: 300;
  border: none;
  background: none;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.#{$css-prefix}-credit-lock__info-description__footnote-anchor {
  color: $color-text-main;
  text-decoration: none;
  &:hover {
    color: $color-text-main;
    text-decoration: underline;
  }
}

.#{$css-prefix}-credit-lock__info-description__footnote-link {
  color: $color-text-main;
  &:hover {
    color: $color-text-main;
  }
}

.#{$css-prefix}-credit-lock__faqs__accordion--title {
  pointer-events: none;
}
