// ----------------------------------------------------------------------------------------
// Base typography settings
// ----------------------------------------------------------------------------------------

body {
  font-size: 14px;
  font-family: $font-family-base;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }
}

// headings
h1, h2, h3, h4, h5, h6 {
  margin: 0;
  font-family: $font-family-base;
  font-weight: 700;
}

pre, code, kbd {
  font-family: $font-family-monospace;
}

button {
  font-family: $font-family-base;
}