$css-component-prefix: c !default;
$css-prefix: ev !default;

.#{$css-component-prefix}-banner {
  position: relative;
  height: 170px;
  background-image: url('/images/efxdatabreach/ev-banner.png');
} 

.#{$css-prefix}-banner__content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 65%;
  text-align: center;
  webkit-transform: translate(-50%, -50%);
  -ms-transform:    translate(-50%, -50%);
  transform:        translate(-50%, -50%);
}

.#{$css-prefix}-banner__subtitle {
  font-size: 1rem;
}

.#{$css-component-prefix}-banner--hidden-mobile {
  display: none;
  @media only screen and (min-width: 768px) {
    display: block;
  }
} 



