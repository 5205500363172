// Css namespace - based on BEMIT
$css-prefix:                  ev !default;

.#{$css-prefix}-freeze-lock__hero {
  padding: 2em 1em;
  background-color: $color-bg-alt-accent;
  text-align: center;
  color: $color-text-alt;
}

.#{$css-prefix}-freeze-lock__hero-title {
  margin: 0.67em 0;
}

.#{$css-prefix}-freeze-lock__instructions {
  font-size: 0.875rem;
}

.#{$css-prefix}-freeze-lock {
  max-width: 32rem;
  padding: 3.5em 2em 0 2em;
}

.#{$css-prefix}-freeze-lock__pin-title {
  margin-bottom: -0.5em;
  font-size: 1.17rem; //h3 size
}

.#{$css-prefix}-freeze-lock__help-button {
  padding: 0;
  border: none;
  font-size: 0.75rem;
  color: $color-text-main;
  text-decoration: underline;
  background-color: transparent;
  &:hover {
    color: $color-text-main;
    text-decoration: underline;
    background-color: transparent;
    cursor: pointer;
  }
}

.#{$css-prefix}-freeze-lock__buttons {
  padding: 2em 0;
  text-align: center;
}

.#{$css-prefix}-freeze-lock__buttons__link {
  font-size: 0.875rem;
}