// Css namespace for objects - based on BEMIT
$css-object-prefix:           o !default;

// Main layout
.#{$css-object-prefix}-container {
  margin-right: auto;
  margin-left: auto;
  padding-right: 2em;
  padding-left: 2em;
  @media only screen and (min-width: 768px) {
    width: 670px;
  }
  @media only screen and (min-width: 992px) {
    width: 830px;
  }
  @media only screen and (min-width: 1200px) {
    width: 1170px;
  }
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
}

.#{$css-object-prefix}-portal-content {
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
}