$css-component-prefix: c !default;

.#{$css-component-prefix}-modal {
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  visibility: hidden;
  width: 100%;
  height: 100%;
  padding: 100px 1em;
  background-color: rgba(51,62,72,0.7);
  overflow: auto;
  z-index: 3;
}

.#{$css-component-prefix}-modal--active {
  display: block;
  visibility: visible;
} 

.#{$css-component-prefix}-modal__content {
  position: relative;
  max-width: 38rem;
  margin: auto;
  padding: 2em 1em 3em 1em;
  background-color: $color-alert-banner;
  text-align: center;
  border-radius: 10px;
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  @media only screen and (min-width: 768px) {
    padding: 4em;
  }
}

.#{$css-component-prefix}-modal__close {
  position: absolute;
  top: 0.5em;
  right: 0.05em;
  padding: 0;
  font-size: 2.5rem;
  border: none;
  background: none;
  color: $color-text-main;
  text-decoration: none;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  &:hover {
    color: $color-text-main;
    text-decoration: none;
  }
}

.#{$css-component-prefix}-modal__icon {
  display: inline-block;
  height: 43px; 
  margin: 0.5em;
}

.#{$css-component-prefix}-modal__title {
  display: block;
  margin: 0.5em 0.5em 1em 0.5em;
  font-size: 1.17rem;
}

.#{$css-component-prefix}-modal__message {
  display: block;
  margin: 1em;
  color: $color-alert-text;
  font-size: 0.875rem;
}

.#{$css-component-prefix}-modal__phone {
  display: inline-block;
  width: 90%;
  margin: 1em 0;
  padding: 1em;
  color: $color-text-alt;
  font-size: 0.9rem;
  font-weight: 700;
  text-decoration: none;
  background-color: $color-button-inverted;
  border-radius: 25px;
  &:hover {
    color: $color-text-alt;
    text-decoration: none;
    background-color: $color-button-inverted-hover;
    @media only screen and (min-width: 768px) {
      color: $color-alert-text;
      background-color: transparent;
    }
  }
  @media only screen and (min-width: 768px) {
    width: auto;
    margin: 0;
    padding: 0.5em;
    color: $color-alert-text;
    font-size: 1.5rem;
    background-color: transparent;
    border-radius: 0;
  }
}

.#{$css-component-prefix}-modal__fine-print {
  display: block;
  margin: 0.5em;
  font-size: 0.875rem;
}

@-moz-keyframes animatetop {
  from { top: -300px; opacity: 0; } 
  to { top: 0; opacity: 1; }
}

@-webkit-keyframes animatetop {
  from { top: -300px; opacity: 0; } 
  to { top: 0; opacity: 1; }
}

@keyframes animatetop {
  from { top: -300px; opacity: 0; }
  to { top: 0; opacity: 1; }
}