// Css namespace for objects - based on BEMIT
$css-prefix:                  ev !default;
// File specific variables
$faq-icon-spacing:            2em;

.#{$css-prefix}-faqs {
  max-width: 60rem;
  padding-bottom: 4em;
  margin-top: 50px;
}

.#{$css-prefix}-faqs__header {
  padding: 1em;
  text-align: center;
}

.#{$css-prefix}-faqs__section__accordion-button {
  cursor: pointer;
  width: 100%;
  padding: 1em 1em 1em 0;
  border: none; //button override
  border-top: 2px solid $color-border-secondary;
  border-radius: 0; //button override
  font-size: 1.38rem;
  font-weight: 700;
  font-family: $font-family-base;
  line-height: 1.5rem;
  text-align: left;
  background: transparent;
  &:first-of-type {
    border-top: none;
  }
  &:before {
    content: '';
    float: right;
    display: inline-block;
    width: 0.35em;
    height: 0.35em;
    margin-top: 0.4em;
    border-top: 3px solid $color-link-main;
    border-left: 3px solid $color-link-main;
    -webkit-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    transform: rotate(135deg);
  }
  &:active {
    color: $color-text-main;
  }
}

.#{$css-prefix}-faqs__section__accordion-button--open {
  &:before {
    border-top: 0;
    border-bottom: 3px solid $color-link-main;
    border-left: 3px solid $color-link-main;
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

.#{$css-prefix}-faqs__section__accordion-panel {
  display: none;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.#{$css-prefix}-faqs__section__accordion-panel--last {
  border-bottom: 2px solid $color-border-secondary;
}

.#{$css-prefix}-faqs__question__accordion-button {
  cursor: pointer;
  width: 100%;
  padding: 1.5em (1.5em + $faq-icon-spacing) 0.8em 1.5em;
  border: none; //button override
  border-top: 1px solid $color-border-secondary;
  border-radius: 0;
  font-size: 1.17rem;
  font-weight: 700;
  font-family: $font-family-base;
  line-height: 1.35rem;
  text-align: left;
  background: transparent;
  &:before {
    content: '+';
    position: relative;
    right: (-1 * $faq-icon-spacing);
    margin-top: 0.2em;
    float: right;
    color: $color-link-main;
    font-size: 1.5rem;
    font-weight: 700;
    -webkit-transform: translateY(-25%);
    -ms-transform: translateY(-25%);
    transform: translateY(-25%);
  }
  &:active {
    color: $color-text-main;
  }
}

.#{$css-prefix}-faqs__question__accordion-button--open {
  &:before {
    content: '−';
  }
}

.#{$css-prefix}-faqs__question__accordion-button--question {
  color: $color-text-accent;
}

.#{$css-prefix}-faqs__question__accordion-panel {
  max-height: 0;
  overflow: hidden;
  padding: 0 1.75em;
  transition: max-height 0.2s ease-out;
}

.#{$css-prefix}-faqs__question__top-paragraph {
  margin-top: 0;
}

.#{$css-prefix}-faqs__section__accordion--title {
  pointer-events: none;
}

.#{$css-prefix}-faqs__section__link {
  color: $color-text-main;
  &:hover {
    color: $color-text-main;
  }
}