$css-component-prefix: c !default;
$css-prefix: ev !default;

.#{$css-component-prefix}-input__container {
    // Overwritting Materialize styles
    position: relative;
    margin: 1.5em 0 0;
    input[type="text"],
    input[type="email"],
    input[type="password"] {
        font-family: $font-family-base;
        &.#{$css-component-prefix}-input,
        &.#{$css-component-prefix}-input.valid {
            display: block;
            padding: 16px 10px 10px 10px;
            margin: 0;
            border: 1px solid $color-input-main;
            border-radius: 4px;
            box-shadow: none;
            box-sizing: border-box;
            &:focus {
                box-shadow: none;
                border: 1px solid $color-input-secondary;
                + label {
                    color: $color-input-secondary;
                }    
            }
        }
        &.#{$css-component-prefix}-input.invalid {
            box-shadow: none;
            border: 1px solid $color-text-error;
            + label {
                color: $color-text-error;
            }                
        }
        &.#{$css-component-prefix}-input.validate + label {
            width: initial;
        }
    }

    .#{$css-component-prefix}-label {
        position: absolute;
        width: initial;
        top: 0;
        left: 10px;
        font-size: 1rem;
        color: $color-input-main;
        cursor: text;
        pointer-events: none;
        transition: all .25s ease-out;
        -webkit-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
        transform-origin: 0% 100%;
        text-align: initial;
        -webkit-transform: translateY(12px);
        -ms-transform: translateY(12px);
        transform: translateY(12px);
        &:not(.label-icon).active {
            width: initial;
            height: initial;
            padding: 0 5px;
            color: inherit;
            font-weight: 700;
            background-color: white;
            transform:translateY(-10px) scale(0.8);
            -webkit-transform: translateY(-10px) scale(0.8);
            -ms-transform:  translateY(-10px) scale(0.8);
            transform-origin: 0 0;
        }
        &.active {
            top: 0;
            border-color: transparent;
        }
    }
}

.#{$css-prefix}-input-error {
    display: none;
    padding: 1em;
    text-align: left;
    background-color: lighten($color-text-error, 40%);
    font-size: 0.8rem;
    color: $color-text-error;
}

.#{$css-component-prefix}-input__lock {
    position: absolute;
    right: 15px;
    top: 15px;
    color: $color-text-secondary;
}