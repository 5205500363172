$css-component-prefix: c !default;

.#{$css-component-prefix}-alert {
  position: relative;
  height: 0;
  opacity: 0;
  background-color: $color-alert-banner;
  transition: all 0.3s ease-out;
} 

.#{$css-component-prefix}-alert--active {
  height: 100px;
  opacity: 1;
} 

.#{$css-component-prefix}-alert__message {
  position: relative;
  vertical-align: middle;
  display: none;
  top: 50%;
  width: 80%;
  margin: 0 0 0 1em;
  color: $color-alert-text;
  text-align: center;
  opacity: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform:    translate(0, -50%);
  transform:        translate(0, -50%);
  transition: opacity 0.6s ease-out;
  @media only screen and (min-width: 768px) {
    width: 850px;
    margin: auto;
  }
}

.#{$css-component-prefix}-alert--active .#{$css-component-prefix}-alert__message {
  opacity: 1;
  display: block;
}

.#{$css-component-prefix}-alert__message__icon {
  vertical-align: middle;
  display: inline-block;
  height: 28px;
  padding-right: 1em;
  @media only screen and (min-width: 768px) {
    height: 43px; //size of the smaller icon, to make them display at the same size
  }
}

.#{$css-component-prefix}-alert__message__text {
  vertical-align: middle;
  display: inline-block;
  max-width: 80%;
  text-align: left;
}

.#{$css-component-prefix}-alert__close {
  position: absolute;
  top: 50%;
  right: 0.2em;
  padding: 0;
  font-size: 2rem;
  border: none;
  background: none;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform:    translate(-50%, -50%);
  transform:        translate(-50%, -50%);
  @media only screen and (min-width: 768px) {
    right: 0.6em;
    font-size: 2.5rem;
  }
}

.#{$css-component-prefix}-alert__break {
  display: none;
  @media only screen and (min-width: 768px) {
    display: initial;
  }
}
