* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: $color-bg-main;
  color: $color-text-main;
}
a {
  color: $color-link-main;
  text-decoration: underline;
  &:hover {
    color: $color-link-main;
    text-decoration: underline;
  }
}