$css-prefix: ev !default;

.#{$css-prefix}-passwordreset {
  position: relative;
  margin: 0 auto 2.25em;
  width: initial;
  max-width: 460px;
}

.#{$css-prefix}-passwordreset__header {
  padding: 1.5em 0;
  @media only screen and (min-width: 768px) {
    padding: 2.75em 0 1.5em;
  }
  text-align: center;
}

.#{$css-prefix}-continue__button {
  padding: 1.5em 0 0;
}

.#{$css-prefix}-reset-password__item {
  position: relative;
  margin: 1.5em 0;
}

.#{$css-prefix}-reset-password__item__description {
  font-size: 0.75rem;
  font-weight: 700;
  font-family: $font-family-primary;
}

.#{$css-prefix}-reset-password--user-info__description {
  font-size: 0.85rem;
  font-family: $font-family-primary;
}

.#{$css-prefix}-reset-password--success__description {
    margin-top: 1.5em;
        @media only screen and (min-width: 768px) {
            margin-top: 2.75em;
        }
    text-align:center;
}
