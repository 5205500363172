// Sticky footer layout
// -----------------------------------------------

// Css namespace for objects - based on BEMIT
$css-object-prefix:           o !default;

html {
  min-height: 100%;
  position: relative;
}

body {
  margin-bottom: $footer-height-mobile; // same as footer fixed height
  @media only screen and (min-width: 768px) {
    margin-bottom: $footer-height; // same as footer fixed height
  }
}

.#{$css-object-prefix}-sticky-footer {
  bottom: 0;
  height: $footer-height-mobile; // footer needs a fixed height
  position: absolute;
  width: 100%;
  @media only screen and (min-width: 768px) {
    height: $footer-height; // footer needs a fixed height
  }
}
