$css-prefix: ev !default;

.#{$css-prefix}-progress-bar {
  display: block;
  margin: 0.75em auto 1.5em;
  text-align: center;
}

.#{$css-prefix}-progress-bar__step {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 25px;
  margin: 3px 65px 3px 0;
  background-color: $color-progress-bar;
  border: 3px solid $color-progress-bar;
  border-radius: 40px;
  @media only screen and (min-width: 768px) {
    width: 20px;
    height: 20px;
    margin-right: 115px;
  }
  &:after {
    background-color: $color-progress-bar;
  }
  &:last-of-type {
    margin-right: 0;
  }
}

.#{$css-prefix}-progress-bar__step--bar-before:after {
  content: "";
  position: absolute;
  top: 45%;
  left: -90px;
  width: 90px;
  height: 3px;
  background-color: $color-progress-bar;
  z-index: -1;
  @media only screen and (min-width: 768px) {
    left: -140px;
    width: 140px;
  }
}

.#{$css-prefix}-progress-bar__step__check {
  position: absolute;
  top: 4px;
  left: 3px;
  color: $color-text-alt;
  font-size: 0.8rem;
  @media only screen and (min-width: 768px) {
    top: 2px;
    left: 2px;
    font-size: 0.65rem;
  }
}