$css-prefix: ev !default;

.#{$css-prefix}-verification {
  margin: 0 auto 2.25em;
  width: initial;
  max-width: 460px;
}

.#{$css-prefix}-verification__title {
  padding: 1.5em 0 0;
  @media only screen and (min-width: 768px) {
    padding: 2.75em 0 0;
  }
}

.#{$css-prefix}-captcha {
  margin: 1.5em auto;
  width: 304px; // fixed width
}

.#{$css-prefix}-verification-question__text {
  padding: 0;
  color: $color-link-secondary;
  font-size: 0.75rem;
  text-decoration: underline;
  background-color: transparent;
  border: none;
  &:hover {
    color: $color-link-secondary;
    background-color: transparent;
    cursor: pointer;
  }
}

.#{$css-prefix}-verification-failed__container {
  height: 100vh;
  background-color: $color-alert-banner;
}

.#{$css-prefix}-verification-failed__content {
  position: relative;
  top: 30%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  @media only screen and (min-width: 768px) {
    top: 40%;
  }
}

.#{$css-prefix}-verification-failed__title {
  display: block;
  margin: 0.5em 0.5em 1em 0.5em;
}

.#{$css-prefix}-verification-failed__icon {
  display: inline-block;
  height: 43px;
  margin: 0.5em;
}

.#{$css-prefix}-verification-failed__message {
  display: block;
  margin: 1em;
  color: $color-alert-text;
  font-size: 0.8rem;
}

.#{$css-prefix}-verification-failed__phone {
  display: inline-block;
  width: 90%;
  margin: 1em 0;
  padding: 1em;
  color: $color-text-alt;
  font-size: 0.9rem;
  font-weight: 700;
  text-decoration: none;
  background-color: $color-button-inverted;
  border-radius: 25px;
  &:hover {
    color: $color-text-alt;
    text-decoration: none;
    background-color: $color-button-inverted-hover;
    @media only screen and (min-width: 768px) {
      color: $color-alert-text;
      background-color: transparent;
    }
  }
  @media only screen and (min-width: 768px) {
    width: auto;
    margin: 0;
    padding: 0.5em;
    color: $color-alert-text;
    font-size: 1.5rem;
    background-color: transparent;
    border-radius: 0;
  }
}

.#{$css-prefix}-verification-failed__fine-print {
  display: block;
  margin: 0.5em 3em;
  font-size: 0.75rem;
}

.#{$css-prefix}-secured__image {
  display: block;
  margin: 3em auto 2em;
}