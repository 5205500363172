.#{$css-prefix}-password-rules {
  font-size: 0.85rem;
  @media only screen and (min-width: 1024px) {
    position: absolute;
    left: 100%;
    margin: 0 1.5em 1.5em 1.5em;
    padding: 1.5em;
    border: 1px solid $color-input-main;
    border-right: none;
    width: 280px;
    &:before {
      position: absolute;
      top: 38%;
      left: -9px;
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 9px solid transparent;
      border-right: 9px solid $color-border-tertiary;
      border-bottom: 9px solid transparent;
      content: '';
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      z-index: 2;
    }
    &:after {
      position: absolute;
      top: 38%;
      left: -10px;
      display: inline-block;
      width: 0;
      height: 0;
      border-top: 10px solid transparent;
      border-right: 10px solid $color-input-main;
      border-bottom: 10px solid transparent;
      content: '';
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }
  }
}

.#{$css-prefix}-password-rules--forgot-password {
  @media only screen and (min-width: 1024px) {
    &:before {
      top: 9%
    }
    &:after {
      top: 9%
    }
  }
}


.#{$css-prefix}-password-rules__header {
  margin: 0.5em 0;
  font-size: 0.85rem;
  @media only screen and (min-width: 1024px) {
    font-size: 1.1rem;
    margin: 0 0 0.5em 0;
  }
}


.#{$css-prefix}-password-rule {
  margin: 0.5em 0;
  @media only screen and (min-width: 1024px) {
    position: relative;
    margin: 0 0 0.3em 0;
  }
}

.#{$css-prefix}-password-rule__icon {
  color: $color-input-main;
  @media only screen and (min-width: 1024px) {
    position: absolute;
    top: 4px;
  }
}

.#{$css-prefix}-password-rule-valid {
  color: $color-border-alt-accent;
}

.#{$css-prefix}-password-rule-invalid {
  color: $color-text-error;
}

.#{$css-prefix}-password-rule__block {
  display: inline;
  @media only screen and (min-width: 1024px) {
    padding-left: 1.45em
  }
}

.#{$css-prefix}-password-rule__description {
  margin: 0 0.25em;
  @media only screen and (min-width: 1024px) {
    margin: 0;
  }
}