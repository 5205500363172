// ----------------------------------------------------------------------------------------
// Local Web Fonts
// ----------------------------------------------------------------------------------------

// Open Sans - Charset latin, version 13, Oct 2016
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("efxdatabreach/fonts/opensans/everlock/OpenSans-Regular.eot"); // IE9 Compat Modes
  src: local("Open Sans"), local("OpenSans"),
    url("efxdatabreach/fonts/opensans/everlock/OpenSans-Regular.woff2") format("woff2"), // Chrome 26+, Opera 23+, Firefox 39+
    url("efxdatabreach/fonts/opensans/everlock/OpenSans-Regular.woff") format("woff"), // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
    url("efxdatabreach/fonts/opensans/everlock/OpenSans-Regular.ttf") format("truetype"); // Safari, Android, iOS
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("efxdatabreach/fonts/opensans/everlock/OpenSans-Bold.eot"); // IE9 Compat Modes
  src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url("efxdatabreach/fonts/opensans/everlock/OpenSans-Bold.woff2") format("woff2"), // Chrome 26+, Opera 23+, Firefox 39+
    url("efxdatabreach/fonts/opensans/everlock/OpenSans-Bold.woff") format("woff"), // Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+
    url("efxdatabreach/fonts/opensans/everlock/OpenSans-Bold.ttf") format("truetype"); // Safari, Android, iOS
}

/* Open Sans Semi-Bold*/
@font-face {
  font-family: "Open Sans Semi-Bold";
  font-weight: normal;
  src: url("efxdatabreach/fonts/opensans/OpenSans-SemiBold.ttf")  format("truetype");
}

/* Open Sans Semi-Bold Italic*/
@font-face {
  font-family: 'Open Sans Semi-Bold Italic';
  font-weight: normal;
  src: url('efxdatabreach/fonts/opensans/OpenSans-SemiBoldItalic.ttf')  format('truetype');
}

/* Font Awesome Icons */
@font-face {
  font-family: 'FontAwesome';
  font-weight: normal;
  src: url('efxdatabreach/fonts/fontawesome/fontawesome-webfont.ttf')  format('truetype');
}
