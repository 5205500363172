// Css namespace for objects - based on BEMIT
$css-prefix:                  ev !default;

.#{$css-prefix}-support {
  max-width: 36rem;
  margin-top: 2em;
  padding-bottom: 4em;
}

.#{$css-prefix}-support__section {
  border-bottom: 1px solid $color-border-secondary;
  padding: 1.5em 0;
}

.#{$css-prefix}-support__link {
  display: block;
  color: $color-text-main;
  text-decoration: none;
  &:before {
    content: '';
    float: right;
    display: inline-block;
    width: 0.55em;
    height: 0.55em;
    margin-top: 0.55em;
    border-top: 3px solid $color-link-main;
    border-right: 3px solid $color-link-main;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:hover {
    color: $color-text-main;
    text-decoration: none;
  }
}

.#{$css-prefix}-support__link__content {
  padding-right: 3em;
}

.#{$css-prefix}-support__description {
  margin-top: 0.5em;
}

.#{$css-prefix}-support__description--no-link {
  margin-bottom: 0;
}

.#{$css-prefix}-support__title {
  font-size: 1.17rem;
}

.#{$css-prefix}-support-link {
  color: $color-text-main;
  &:hover {
    color: $color-text-main;
  }
}