$css-prefix: ev !default;

.#{$css-prefix}-myaccount {
  margin: 0 auto 2.25em;
  width: initial;
  max-width: 460px;
}

.#{$css-prefix}-myaccount__header {
    padding: 1.5em 0 0;
    @media only screen and (min-width: 768px) {
      padding: 2.75em 0 0;
    }
    text-align: center;
}

.#{$css-prefix}-sign-in__button {
  padding: 1.5em 0 0;
  @media only screen and (min-width: 768px) {
    padding: 2.75em 0 0;
  }
}

.#{$css-prefix}-myaccount__link {
  font-size: 0.875rem;
}

.#{$css-prefix}-myaccount__link--forgotpassword {
  display: inline-block;
  margin-top: 5px;
  color: $color-link-secondary;
}

.#{$css-prefix}-myaccount__link--signup {
  display: block;
  color: $color-link-main;
  text-align: center;
}

.#{$css-prefix}-myaccount__text--support {
  font-size: 0.875rem;
}

.#{$css-prefix}-myaccount__phone-link {
  color: $color-text-main;
  font-weight: 700;
  @media only screen and (min-width: 768px) {
    text-decoration: none;
  }
  &:hover {
    color: $color-text-main;
  }
}