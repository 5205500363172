// Css namespace for objects - based on BEMIT
$css-object-prefix:           o !default;
$css-prefix:                  ev !default;

.#{$css-object-prefix}-footer {
  padding-top: 1.5em;
  background-color: $color-bg-alt;
  color: $color-text-alt;
  text-align: center;
  @media only screen and (min-width: 768px) {
    text-align: left;
  }
}

.#{$css-prefix}-footer__information {
  width: 100%;
  display: inline-block;
  @media only screen and (min-width: 768px) {
    width: 50%;
  }
}

.#{$css-prefix}-footer__information--left {
  float: none;
  padding: 0.5em;
  @media only screen and (min-width: 768px) {
    float: left;
    padding: 0 1em 0 0;
  }
}

.#{$css-prefix}-footer__information--right {
  float: none;
  padding: 0.5em;
  text-align: center;
  @media only screen and (min-width: 768px) {
    float: right;
    padding: 0 0 0 1em;
    text-align: right;
  }
}

.#{$css-prefix}-footer__disclaimer {
  padding: 0.25em 0;
  font-size: 0.75rem;
}

.#{$css-prefix}-footer__links {
  padding: 0 0.25em;
  font-size: 0.75rem;
  color: $color-text-alt;
  text-decoration: none;

  &:hover {
    border-bottom: none;
    color: $color-text-alt;
    text-decoration: underline;
  }
}