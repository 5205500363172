// ----------------------------------------------------------------------------------------
// Utility mixins
// ----------------------------------------------------------------------------------------

// Mixin to make elements only visible for a screen reader
// It's visually hidden and occupies no space

@mixin ev-sr-only () {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0,0,0,0);
  border: 0;
}