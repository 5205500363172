$css-prefix: ev !default;

/** Overview **/

.#{$css-prefix}-account {
  position: relative;
  width: initial;
  max-width: 600px;
  padding: 2em 2em 4em;
}

.#{$css-prefix}-account__title {
  padding: 2em;
  border-bottom: 1px solid $color-border-section-separator;
}

.#{$css-prefix}-account__title__header {
  font-size: 1.125rem;
}

.#{$css-prefix}-account__item {
  position: relative;
  padding: 2em 0 2em 2em;
  border-bottom: 1px solid $color-border-section-separator;
  @media only screen and (min-width: 768px) {
    padding-left: 4em;
  }
}

.#{$css-prefix}-account__item--no-icon {
  padding: 1.5em 2em;
  margin: 0;
}

.#{$css-prefix}-account__item__icon {
  position: absolute;
  left: 0;
  @media only screen and (min-width: 768px) {
    left: 2em;
  }
}

.#{$css-prefix}-account__item__icon--email {
  width: 18px;
}

.#{$css-prefix}-account__item__icon--lock {
  width: 16px;
}

.#{$css-prefix}-account__item__icon--phone {
  width: 14px;
}

.#{$css-prefix}-account__item__type {
  margin: 0;
  font-size: 1rem;
  color: $color-text-alt-secondary;
  @media only screen and (max-width: 768px) {
    max-width: 150px;
  }
}

.#{$css-prefix}-account__item__type--name {
  margin: 0;
  font-size: 1rem;
}

.#{$css-prefix}-account__item__value {
  margin-top: 0.4em;
  font-size: 0.875rem;
  overflow-wrap: break-word;
  max-width: 100%;
}

.#{$css-prefix}-account__item__edit {
  position: absolute;
  right: 1.5em;
  font-size: 0.85rem;
  color: $color-bg-accent;
}

.#{$css-prefix}-account__item__toggle {
  position: absolute;
  right: 1.5em;
  top: 1em;
  font-size: 0.85rem;
}

.#{$css-prefix}-account__item__edit__link {
  margin-left: 3px;
  text-decoration: none;
}

.#{$css-prefix}-account__item__text-alerts {
  position: relative;
}

.#{$css-prefix}-account__item__description-header {
  display: inline-block;
  margin-top: 1em;
  font-size: 0.85rem;
  font-weight: 700;
  font-family: $font-family-primary;
}

.#{$css-prefix}-account__item__description {
  width: 70%;
  margin-top: 0.4em;
  font-size: 0.75rem;
}

.#{$css-prefix}-account__footnote {
  font-size: 0.75rem;
  font-weight: 700;
  font-family: $font-family-primary;
  text-align: center;
}

.#{$css-prefix}-account-update {
  position: relative;
  width: initial;
  max-width: 460px;
  padding: 2em 1em 6em;
}

.#{$css-prefix}-account-update__save-button {
  margin: 1em 0;
}

.#{$css-prefix}-account-update__title {
  margin-bottom: 1em;
  padding: 1em 0 1.5em;
  border-bottom: 1px solid $color-border-section-separator;
  font-size: 1.875rem;
  text-align: center;
  @media only screen and (min-width: 768px) {
    font-size: 1.125rem;
    text-align: initial;
  }
}

.#{$css-prefix}-account-update__header {
  margin-bottom: 1.5em;
  font-size: 1.125rem;
  @media only screen and (min-width: 768px) {
    font-size: 0.875rem;
  }
}

.#{$css-prefix}-account-update__description {
  font-size: 0.75rem;
}

.#{$css-prefix}-account-update__item {
  position: relative;
  margin: 1.5em 0;
}

.#{$css-prefix}-account-update__item__subtext {
  font-size: 0.7rem;
}

.#{$css-prefix}-account-update__phone-checkbox {
  position: absolute;
  top: 3px;
}

.#{$css-prefix}-account-update__phone-label {
  display: inline-block;
  padding-left: 30px; // offset for the checkbox to the side.
}

input:checked + .#{$css-prefix}-account__slider--disabled {
  background-color: $efx-lighter-grey;
  &:hover {
    cursor: default;
  }
}