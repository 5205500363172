// ----------------------------------------------------------------------------------------
// Common helper classes
// ----------------------------------------------------------------------------------------
$css-prefix:                  ev !default;
$css-utility-prefix:          u !default;

// helper class for hiding containers.
.#{$css-prefix}--hidden {
  display: none !important;
}

// Class to make elements only visible with a screen reader
// It's visually hidden and occupies no space
.#{$css-utility-prefix}-sr-only {
  @include ev-sr-only;
}


// helper for hiding elements on tablet and up
.#{$css-utility-prefix}-hidden-md {
  @media only screen and (min-width: 768px) {
    display: none;
    visibility: hidden;
  }
}

// helper for hiding elements on mobile
.#{$css-utility-prefix}-hidden-sm {
  @media only screen and (max-width: 767px) {
    display: none;
    visibility: hidden;
  }
}

.#{$css-utility-prefix}-list-unstyled {
  margin: 0;
  padding: 0;
  list-style: none;
}