$css-prefix: ev !default;

.#{$css-prefix}-kba {
  margin: 0 auto 2.25em;
  width: initial;
  max-width: 460px;
}

.#{$css-prefix}-kba__questions {
  color: $color-text-tertiary;
  text-align: left;
}

.#{$css-prefix}-kba__question {
  padding-bottom: 2em;
}

.#{$css-prefix}-kba__question__header {
  padding-bottom: 1em;
  color: $color-text-tertiary;
}

.#{$css-prefix}-kba__answers {
  padding-top: 0;
  color: $color-text-tertiary;
}
