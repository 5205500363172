// Css namespace for objects - based on BEMIT
$css-object-prefix:           o !default;
$css-prefix:                  ev !default;

.#{$css-object-prefix}-header {
  position: relative;
  width: 100%;
  min-height: 45px;
  margin-bottom: 2px; //offset box shadow
  background-color: $color-bg-main;
  color: $color-text-main;
  text-align: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.09); //copied from dist
}

.#{$css-prefix}-header__efx-logo {
  display: none;
  @media only screen and (min-width: 768px) {
    display: inline-block;
    float: left;
    margin-left: 2.5em;
    padding: 0.15em 0.75em;
    background-color: $color-bg-accent;
  }
}

.#{$css-prefix}-header__efx-logo__logo {
  padding-top: 0.5em;
  height: 24px;
}

.#{$css-prefix}-header__product-logo {
  display: inline-block;
  padding-top: 1em;
  position: absolute;
  height: 1em;
  padding: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.#{$css-prefix}-header__navigation-input {
  display: none;
}

.#{$css-prefix}-header__navigation-overlay {
  transition: background-color 0.4s ease-out;
}

.#{$css-prefix}-header__navigation-overlay--active {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(51,62,72,0.7);
  z-index: 3;
  @media only screen and (min-width: 768px) {
    position: initial;
    width: initial;
    height: initial;
    background-color: initial;
  }
  .#{$css-prefix}-header__navigation {
    display: block;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    border-bottom: 1px solid $color-input-main;
    @media only screen and (min-width: 768px) {
      display: inline-block;
      border-bottom: none;
      -webkit-transform: translate(0, -100%);
      -ms-transform: translate(0, -100%);
      transform: translate(0, -100%);
    }
  }
}

.#{$css-prefix}-header__navigation {
  position: absolute;
  width: 100%;
  z-index: 2; // sits above banner; below modals;
   -webkit-transform: translate(0, -100%);
  -ms-transform: translate(0, -100%);
  transform: translate(0, -100%);
  transition: transform .4s ease-out;
  @media only screen and (min-width: 768px) {
    right: 10px;
    top: 100%;
    display: inline-block;
    width: auto;
    max-height: none;
    margin-bottom: -1em; // to align hover/selected border
    font-size: 0.9rem;
    transition: none;
  }
}

.#{$css-prefix}-header__navigation__header {
  position: relative;
  width: 100%;
  height: 3em;
  background-color: $color-bg-main;
  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.#{$css-prefix}-header__navigation__title {
  position: absolute;
  top: 50%;
  left: 1em;
  font-size: 1.2em;
  font-weight: 700;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.#{$css-prefix}-header__navigation__close-button {
  position: absolute;
  top: 50%;
  right: 0.5em;
  border: 0;
  font-size: 2rem;
  background-color: transparent;
  cursor: pointer;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.#{$css-prefix}-header__navigation__item {
  display: block;
  border-top: 1px solid $color-input-main;
  background-color: $color-bg-main;
  @media only screen and (min-width: 768px) {
    display: inline-block;
    border-top: none;
  }
}

.#{$css-prefix}-header__navigation__link {
  display: block;
  width: 100%;
  padding: 1em 1em 1em 1.5em;
  border-bottom: none;
  text-decoration: none;
  text-align: left;
  color: $color-text-main;
  &:hover {
    border-bottom: none;
    text-decoration: none;
  }
  @media only screen and (min-width: 768px) {
    width: auto;
    display: inline-block;
    color: $color-text-accent;
    padding: 7px; //need to be able to subtract 2px from this, so in px instead of ems
    &:hover {
      border-bottom: 2px solid $color-link-main;
      text-decoration: none;
    }
  }
}

.#{$css-prefix}-header__navigation__link__icon {
  height: 1.4em;
  padding-right: 1em;
  vertical-align: middle;
  opacity: 0.5;
  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.#{$css-prefix}-header__navigation__link--active {
  @media only screen and (min-width: 768px) {
    padding-bottom: 5px;
    color: $color-text-main;
    font-weight: 700;
    border-bottom: 4px solid $color-text-main;
    &:hover {
      color: $color-text-main;
      border-bottom: 4px solid $color-text-main;
    }
  }
  .#{$css-prefix}-header__navigation__link__icon {
    opacity: 1;
  }
}


.#{$css-prefix}-header__navigation-button {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 1.4em;
  width: 3em;
  height: 3em;
  background-color: transparent;
  border: none;
  border-radius: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  user-select: none;
  @media only screen and (min-width: 768px) {
    display: none;
  }
}

.#{$css-prefix}-header__navigation-icon {
  position: relative;
  display: inline-block;
  width: 1.5em;
  height: 2px;
  background: $color-bg-alt;
  transition: background .2s ease-out;
  &:before {
    position: absolute;
    top: 5px;
    display: block;
    width: 100%;
    height: 100%;
    background: $color-bg-alt;
    content: '';
    transition: all .2s ease-out;
  }
  &:after {
    position: absolute;
    top: -5px;
    display: block;
    width: 100%;
    height: 100%;
    background: $color-bg-alt;
    content: '';
    transition: all .2s ease-out;
  }
}