// ----------------------------------------------------------------------------------------
// Lock & Alerts theme variables
// ----------------------------------------------------------------------------------------


// Css custom Namespace
// --------------------------------------------------
// Css namespace for the widgets, can be set globally for all widgets
// to prevent conflicting css classes and inheritance.
$css-prefix:                  ev !default;

// Css namespace for components, objects and utilities - based on BEMIT
$css-component-prefix:        c !default;
$css-object-prefix:           o !default;
$css-utility-prefix:          u !default;


// Default Theme color swatch
// --------------------------------------------------
// Define the basic color palette.
// These variables are then used to define the rest of the color variables in this file.
// They should ONLY be used on this partial.

// Efx themed color scale
//
// This was taken from existing EFX websites
$efx-vivid-burgundy:      #a31f34;
$efx-spanish-red:         #e70034;
$efx-plum:                #94348c;
$efx-dark-maroon:         #981e32;
$efx-cg-blue:             #0076a3;
$efx-rich-electric-blue:  #1F6FCC;
$efx-navy-blue:           #08415c;
$efx-baby-blue:           #D8EDF8;
$efx-dull-blue:           #32708E;
$efx-may-green:           #50a240;
$efx-inchworm-green:      #5BC247;
$efx-vivid-gamboge-gold:  #ff9908;


// Equifax adjusted Gray Scale
//
// Taken from existing EFX websites
$efx-black:               #000000;
$efx-rich-black:          #141414;
$efx-dark-grey:           #333e48;
$efx-medium-grey:         #97a6b4;
$efx-grey:                #5a5a5a;
$efx-light-grey:          #a3b0ba;
$efx-lighter-grey:        #eaebec;
$efx-off-white:           #f1f4f5;
$efx-white:               #ffffff;
// Equifax-based adjusted color scheme
// --------------------------------------------------
// This color scheme is based off the base EFX colors,
// adjusted for better contrast with lightness correction/beizer interpolation


// Blue scheme
//
// used for charts when comparison is needed
$ev-dark-imperial-blue:  #043c61;
$ev-sapphire-blue:       #03659e;
$ev-rich-electric-blue:  #0093c9; // $efx-rich-electric-blue
$ev-deep-sky-blue:       #0cafeb;
$ev-electric-light-blue: #5fcdf5;


// Rating scheme
//
// used for ratings when a "good" vs "bad" meaning is needed
$ev-cherry-red:          #db2763;
$ev-pumpkin-orange:      #f26738;
$ev-vivid-gamboge-gold:  #ff9908; // $efx-vivid-gamboge-gold
$ev-inchworm-green:      #a5ec5e; // $efx-inchworm-green
$ev-max-blue-green:      #37bfd6;
// $ev-electric-light-blue is the sixth color in this scheme.




// Basic color variables
// --------------------------------------------------
// These are used thorough the partials to define text colors, backgrounds and borders.

$color-bg-main:                   $efx-white;
$color-bg-alt:                    $efx-dark-grey;

$color-bg-secondary:              $efx-lighter-grey;
$color-bg-tertiary:               $efx-off-white;

$color-bg-alt-secondary:          $efx-grey;

$color-bg-accent:                 $efx-spanish-red;
$color-bg-alt-accent:             $efx-rich-electric-blue;
$color-bg-locked:                 $efx-inchworm-green;
$color-bg-frozen:                 $efx-rich-electric-blue;

$color-button-primary:            $efx-spanish-red;
$color-button-primary-hover:      $efx-dark-maroon;
$color-button-inverted:           $efx-dark-grey;
$color-button-inverted-hover:     $efx-black;
$color-button-disabled:           #c5cac9;

$color-input-main:                #d1d4d3; // Between light grey and lighter grey
$color-input-secondary:           $efx-dark-grey;


$color-border-main:               $efx-light-grey;

$color-border-main:               $efx-dark-grey;

$color-border-secondary:          $efx-lighter-grey;
$color-border-tertiary:           $efx-white;
$color-border-accent:             $efx-spanish-red;
$color-border-alt-accent:         $efx-inchworm-green;
$color-border-link-separator:     $efx-light-grey;
$color-border-heading-separator:  $efx-lighter-grey;
$color-border-section-separator:  #d1d4d3; // Between light grey and lighter grey
$color-border-focus:              $efx-rich-electric-blue;

$color-shadow-main:               $efx-light-grey;

$color-headings-main:             $efx-black;
$color-text-main:                 $efx-black;
$color-text-secondary:            $efx-grey;
$color-text-tertiary:             #45555F;
$color-text-strong:               $efx-black;
$color-text-strong-secondary:     lighten($efx-black, 2%);
$color-text-alt:                  $efx-white;
$color-text-alt-secondary:        #5f6a72; // Light grey
$color-text-accent:               $efx-spanish-red;
$color-text-featured:             $efx-cg-blue;

$color-link-main:                 $efx-spanish-red;
$color-link-secondary:            $efx-dark-grey;
$color-link-active:               $efx-spanish-red;
$color-link-alt:                  $efx-white;
$color-link-alt-active:           $efx-white;

$color-alert-banner:              $efx-baby-blue;
$color-alert-text:                $efx-dull-blue;

$color-progress-bar:              #3fae29; // grass green

// status colors
$color-bg-error:                  lighten($ev-cherry-red, 50%);
$color-text-error:                $ev-cherry-red;
$color-border-error:              $ev-cherry-red;

$color-bg-success:                lighten($ev-max-blue-green, 20%);
$color-bg-success-alt:            $ev-max-blue-green;
$color-text-success:              darken($ev-max-blue-green, 10%);
$color-border-success:            $ev-max-blue-green;

$color-border-focus:              $efx-rich-electric-blue;

$color-switch-off:                #cccccc;

// element colors
$pre-bg-color:                    #f5f2f0;

// Base breakpoints and grid settings
// --------------------------------------------------

$breakpoints: (
  "phone": 320px,
  "tablet": 768px,
  "desktop-md": 992px,
  "desktop-lg": 1200px
);


// Base Box Model Variables
// --------------------------------------------------
$z-index-base:                    0;


// Basic typography variables
// --------------------------------------------------
// Fonts for pages
$font-family-base:        "Open Sans", sans-serif !default;
// headings font-face
$font-family-primary:     "Open Sans Semi-Bold", "Open Sans", sans-serif !default;
// Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`
$font-family-monospace:   Menlo, Monaco, Consolas, 'Courier New', monospace !default;


// Footer variables
// --------------------------------------------------
$footer-height-mobile:              270px;
$footer-height:                     160px;


// Materialize Variables
$input-height: auto;
$input-border-color: $color-input-main;
$input-border: 1px solid $input-border-color !default;
$input-background: #fff !default;

$input-error-color: $color-text-error !default;
$input-success-color: $efx-may-green !default;
$input-focus-color: #45555F !default; // blue-tinted grey

$input-font-size: 1rem !default;
$input-margin-bottom: 2px;
$input-margin: 0 0 $input-margin-bottom 0 !default;
$input-padding: 0 !default;
$input-transition: all .3s !default;
$label-font-size: .8rem !default;
$input-disabled-color: rgba(0,0,0, .42) !default;
$input-disabled-solid-color: $efx-medium-grey !default;
$input-disabled-border: 1px dotted $input-disabled-color !default;
$input-invalid-border: 1px solid $input-error-color !default;
$placeholder-text-color: $input-focus-color !default;
$gutter-width: 1.5rem !default;
$medium-and-down: "only screen and (max-width : 992px)" !default;
$small-and-down: "only screen and (max-width : 768px)" !default;
$dropdown-item-height: 50px !default;

$radio-border: 3px solid $efx-inchworm-green;
$radio-fill-color: $efx-white;
$radio-empty-color: $efx-light-grey;