// Css namespace - based on BEMIT
$css-prefix:                  ev !default;

.#{$css-prefix}-activation {
  max-width: 32rem;
  margin-top: 3em;
}

.#{$css-prefix}-activation--password-rules-bumper {
  padding-bottom: 5em;
}

.#{$css-prefix}-activation__form {
  position: relative;
}

.#{$css-prefix}-activation__instructions {
  font-size: 1.17rem;
}

.#{$css-prefix}-activation__instructions--centered {
  text-align: left;
  @media only screen and (min-width: 768px) {
    text-align: center;
  }
}


.#{$css-prefix}-activation__instructions--sign-in {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
}

.#{$css-prefix}-activation__field-description {
  font-size: 0.75rem;
}

.#{$css-prefix}-activation__checkbox {
  margin-top: 1em;
  font-size: 0.75rem;
}

.#{$css-prefix}-activation__disclaimer {
  display: inline-block;
  margin: 0.5em;
  font-size: 0.75rem;
}

.#{$css-prefix}-activation__disclaimer--label {
  float: right;
  width: 90%;
}

.#{$css-prefix}-activation__disclaimer__link {
  color: $color-text-main;
  &:hover {
    color: $color-text-main;
  }
}

.#{$css-prefix}-activation__button {
  margin-top: 2em;
}

.#{$css-prefix}-activation__tou {
  padding-top: 1em;
  border-top: 1px solid $color-border-section-separator;
}