$css-prefix: ev !default;

.#{$css-prefix}-forgotpassword__header {
  padding: 1.5em 0;
  @media only screen and (min-width: 768px) {
    padding: 2.75em 0 1.5em;
  }
  text-align: center;
}

.#{$css-prefix}-forgotpassword {
  width: initial;
  max-width: 460px;
}

.#{$css-prefix}-return__link {
  display: block;
  font-family: $font-family-base;
  font-size: 0.85rem;
  color: $color-link-main;
  text-align: center;
}
