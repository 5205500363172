$css-component-prefix: c !default;

.#{$css-component-prefix}-button {
  vertical-align: middle;
  display: inline-block;
  margin: 0 0 1em;
  padding: 1em;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  font-family: $font-family-primary;
  color: $color-text-alt;
  text-decoration: none;
  background-color: $color-button-primary;
  &:hover {
    border-bottom: none;
    text-decoration: none;
    color: $color-text-alt;
    background-color: $color-button-primary-hover;
  }
}

.#{$css-component-prefix}-button--inverted {
  background-color: $color-button-inverted;
  &:hover {
    background-color: $color-button-inverted-hover;
  }
}

.#{$css-component-prefix}-button--disabled {
  background-color: $color-button-disabled;
  &:hover {
    background-color: $color-button-disabled;
  }
}

.#{$css-component-prefix}-button--short {
  width: 70%;
  margin-top: 2em;
}